import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from "@material-tailwind/react";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ErrorPage from './components/ErrorPage';
import AppProject from './components/AppProject';
import Dashboard from './components/Dashboard';
import About from './components/About';
import Goals from './components/Goals';
import Quizzes from './components/Quizzes';
import QuizEditor from './components/quiz/QuizEditor';
import Agents from './components/Agents';
import Agent from './components/agents/Agent';
import Apps from './components/Apps';
import { RegistrationForm } from './components/RegistrationForm';
import { AppGenerator } from './components/AppGenerator';
import { AgentGenerator } from './components/agents/AgentGenerator';
import Contact from './components/Contact';
import Products from './components/Products';
import News from './components/News';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import License from './components/License';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Affiliates from './components/Affiliates';
import Businesses from './components/Businesses';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about-recommengine",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "apps/:id",
    element: <AppProject />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/agents",
    element: <Agents />,
    errorElement: <ErrorPage />,
  },
  {
    path: "agents/:id",
    element: <Agent />,
  },
  {
    path: "/apps",
    element: <Apps />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/create-new-app",
    element: <AppGenerator />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/create-new-agent",
    element: <AgentGenerator />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <RegistrationForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-in",
    element: <RegistrationForm signIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-out",
    element: <RegistrationForm signOut />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-ios-apps",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-android-apps",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/license",
    element: <License />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/contact-us",
  //   element: <Dashboard />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/goals",
    element: <Goals />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/quizzes",
    element: <Quizzes />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/new-quiz",
    element: <QuizEditor />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <Contact />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/products",
    element: <Products />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/news",
    element: <News />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/services",
    element: <Services />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/affiliates",
    element: <Affiliates />,
    errorElement: <ErrorPage />
  },
  {
    path: "/businesses",
    element: <Businesses />,
    errorElement: <ErrorPage />
  },
  {
    path: "/businesses/:id",
    element: <AboutUs />,
    errorElement: <ErrorPage />
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
