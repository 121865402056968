import React, { useEffect, useState } from 'react';

import './App.css';

import { RegistrationForm } from './components/RegistrationForm';
import { Header } from './components/Header';
import { Footer } from './components/Footer'
import { AppGenerator } from './components/AppGenerator';
import About from './components/About'

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
 } from "firebase/auth";
import Dashboard from './components/Dashboard';
import LoadingIndicator from './components/LoadingIndicator';

 const firebaseConfig = {
  apiKey: "AIzaSyAm-Rxh3eQrAoqIWhTZZw5vKNDk8Bcdeko",
  authDomain: "recommengine-48d51.firebaseapp.com",
  databaseURL: "https://recommengine-48d51-default-rtdb.firebaseio.com",
  projectId: "recommengine-48d51",
  storageBucket: "recommengine-48d51.appspot.com",
  messagingSenderId: "956851828912",
  appId: "1:956851828912:web:0133ecfa1824f0cc542375",
  measurementId: "G-HE62CXXNCP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function App() {
  // const [message, setMessage] = useState('');
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  
  // Check if user is logged in, set state accordingly
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      setLoading(false)
    })
  })

  if (loading) {
    return <div className='flex flex-col w-full h-full'>
      <LoadingIndicator />
    </div>;
  }

  return (
      <div className='flex flex-col w-full h-full'>

        {!loggedIn && (
          <RegistrationForm />
        )}
        
        {loggedIn && (
          <Dashboard />
        )}

      </div>
  );
}

export default App;
