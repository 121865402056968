import React, { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel,
    CardBody
} from "@material-tailwind/react";
import RegularButton from './buttons/RegularButton';

export default function DashboardContent({ currentUser, myApps, myAgents, myQuizzes }) {

    return (
        <div className='p-10 flex flex-col md:flex-row justify-between text-center'>
            <Card className="text-center">
                <CardBody>
                    <Typography variant="h4" color="blue-gray">
                        My Apps
                    </Typography>
                    <div>
                        <Typography>{myApps.length} Apps</Typography>
                        <RegularButton url={"/apps"} text="View All" />
                    </div>
                </CardBody>
            </Card>

            <Card className="text-center">
                <CardBody>
                    <Typography variant="h4" color="blue-gray">
                        My Agents
                    </Typography>

                    <div>
                        <Typography>{myAgents.length} Agents</Typography>
                        <RegularButton url={"/agents"} text="View All" />
                    </div>
                </CardBody>
            </Card>

            <Card className="text-center">
                <CardBody>
                    <Typography variant="h4" color="blue-gray">
                        My Quizzes
                    </Typography>

                    <div>
                        <Typography>{myQuizzes.length} Quizzes</Typography>
                        <RegularButton url={"/quizzes"} text="View All" />
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}