import { Typography } from "@material-tailwind/react";

export function Footer() {
  return (
    <footer className="flex w-full flex-col md:flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between p-2 md:p-10">
      <Typography color="blue-gray" className="font-normal text-sm">
        &copy; 2024 BCP Solutions, LLC
      </Typography>
      <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
        <li>
          <Typography
            as="a"
            href="/affiliates"
            color="blue-gray"
            className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            Become an Affiliate
          </Typography>
        </li>
        <li>
          <Typography
            as="a"
            href="/about-us"
            color="blue-gray"
            className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            About Us
          </Typography>
        </li>
        <li>
          <Typography
            as="a"
            href="/license"
            color="blue-gray"
            className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            License
          </Typography>
        </li>
        <li>
          <Typography
            as="a"
            href="/privacy-policy"
            color="blue-gray"
            className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            Privacy Policy
          </Typography>
        </li>
        <li>
          <Typography
            as="a"
            href="/contact"
            color="blue-gray"
            className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            Contact Us
          </Typography>
        </li>
      </ul>
    </footer>
  );
}