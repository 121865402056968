import React, { useState, useEffect } from 'react'
import {
    // Button,
    // Checkbox,
    Typography,
    // Input,
    // List,
    // ListItem,
    Card,
    // Carousel,
    // CardBody
} from "@material-tailwind/react";


import RegularButton from './buttons/RegularButton';

export default function AppsContent({ currentUser, myApps }) {

    const TABLE_HEAD = ["Name", "Package Name", "ID", "Features", ""];

    return (
        <div className='flex flex-col mt-2'>
            <RegularButton text="Create New App" url="/create-new-app" />
            <Card className="h-full w-full overflow-scroll">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                        <th
                            key={`head-${head}`}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                        >
                            <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                            >
                            {head}
                            </Typography>
                        </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {myApps.map((app, index) => {
                        // console.log('app', app)
                        const isLast = index === myApps.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
            
                        return (
                            <tr key={`app-${app.id}+${index}`}>
                                <td className={classes}>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold"
                                    as="a"
                                    href={'/apps/' + app.id}
                                >
                                    {app.name}
                                </Typography>
                                </td>
                                <td className={classes}>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {app.packageName}
                                </Typography>
                                </td>
                                <td className={classes}>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {app.id}
                                </Typography>
                                </td>
                                <td className={classes}>
                                {app.features && Object.keys(app.features).map((feature) => (
                                    app.features[feature] === true && (
                                        <Typography color="blue-gray" key={`feature-${feature}-${app.id}`}>{feature}</Typography>
                                    )
                                ))}
                                </td>
                                {/* <td className={classes}>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {app.createdAt}
                                </Typography>
                                </td> */}
                                <td className={classes}>
                                    <RegularButton text="View" url={'/apps/' + app.id} />
                                </td>
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Card>
        </div>
    );
}