import React, { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel,
    CardBody,
} from "@material-tailwind/react";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, query, getDocs, collection, doc, deleteDoc } from "firebase/firestore";
import { getDatabase, ref, get } from "firebase/database";

import LoadingIndicator from '../LoadingIndicator';
import RegularButton from '../buttons/RegularButton';

const firebaseConfig = {
    apiKey: "AIzaSyAm-Rxh3eQrAoqIWhTZZw5vKNDk8Bcdeko",
    authDomain: "recommengine-48d51.firebaseapp.com",
    databaseURL: "https://recommengine-48d51-default-rtdb.firebaseio.com",
    projectId: "recommengine-48d51",
    storageBucket: "recommengine-48d51.appspot.com",
    messagingSenderId: "956851828912",
    appId: "1:956851828912:web:0133ecfa1824f0cc542375",
    measurementId: "G-HE62CXXNCP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const realtimeDb = getDatabase(app);

export default function AppProjectContent({ loading, myApp, id }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [appId, setAppId] = useState(id);
    const [localMyApp, setLocalMyApp] = useState(myApp);
    const [localId, setLocalId] = useState(id);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [appPhotos, setAppPhotos] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [mapPositions, setMapPositions] = useState([]);

    const [idToken, setIdToken] = useState(null);

    const retrieveIdToken = async () => {
        const token = await currentUser.getIdToken();
        setIdToken(token);
    };

    // useEffect(() => {
    //     retrieveIdToken();
    // }, [currentUser]);

    // retrieve current user
    // useEffect(() => {
    //     auth.onAuthStateChanged(user => {
    //         if (user) {
    //             setCurrentUser(user);
    //         }
    //     });
    // }, []);

    const retrieveAppPhotos = async (id) => {
        const url = `${firebaseConfig.databaseURL}/recommengine/users/${currentUser.uid}/apps/${id}/photos.json?auth=${idToken}`;
        console.log('url: ', url);

        const response = await fetch(url);
        console.log('Response:', response);
        
    };

    const getAppCalendarEvents = async () => {
        if (loggedIn) {
            const calendarCollection = collection(db, "recommengine/users/" + currentUser.uid + "/data/apps/" + id + "/calendarEvents");
            const q = query(calendarCollection);
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setCalendarEvents(prev => [...prev, doc.data()]);
            });
        } else {
            console.log('user not logged in');
        }
    };

    const getAppPhotos = async () => {
        if (loggedIn) {
            // call retrieveAppPhotos
            const photos = await retrieveAppPhotos(id);
            console.log('photos: ', photos);
            setAppPhotos(photos);
        }
    };
    const getAppPositions = async () => {
        if (loggedIn) {
            const positionsCollection = collection(db, "recommengine/users/" + currentUser.uid + "/data/apps/" + id + "/positions");
            const q = query(positionsCollection);
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setMapPositions(prev => [...prev, doc.data()]);
            });
        } else {
            console.log('user not logged in');
        }
    };

    const getAppChatMessages = async () => {
        if (loggedIn) {
            try {
                // Get the ID token for the currently authenticated user
                const idToken = await currentUser.getIdToken();
    
                // Construct the URL with the ID token as a query parameter
                const url = `${firebaseConfig.databaseURL}/recommengine/users/${currentUser.uid}/apps/${id}/messages.json?auth=${idToken}`;
                console.log('url: ', url);
    
                // Make the request to the Firebase Realtime Database
                const response = await fetch(url);
                console.log('Response:', response);
    
                if (response.ok) {
                    const messages = await response.json();
                    console.log("Retrieved chat messages from Realtime Database:", messages);
    
                    if (messages) {
                        const formattedMessages = Object.keys(messages).map(key => ({
                            id: key,
                            ...messages[key]
                        }));
                        setChatMessages(prev => [...prev, ...formattedMessages]);
                    } else {
                        console.log("No chat messages found in the response");
                    }
                } else {
                    console.log("Failed to retrieve chat messages from Realtime Database");
                }
            } catch (error) {
                console.error("Error fetching chat messages from Realtime Database:", error);
            }
        } else {
            console.log('User not logged in');
        }
    };

    useEffect(() => {
        if (myApp) {
            setLocalMyApp(myApp);
        }
    }, [myApp]);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setLoggedIn(true);
                setCurrentUser(user);

                getAppCalendarEvents();
                getAppChatMessages();
                getAppPositions();
            } else {
                setLoggedIn(false);
                setCurrentUser(null);
            }
        });
    }, [currentUser]);

    const deleteApp = async () => {
        if (loggedIn) {
            console.log('delete app: ', myApp.id);

            const appRef = doc(db, "recommengine/users/" + currentUser.uid + "/data/apps", appId);
            await deleteDoc(appRef);
            console.log('app deleted');

            window.location.href = '/apps';
        } else {
            console.log('user not logged in');
        }
    };

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    if (!myApp) {
        return (
            <div className='flex flex-col w-full h-full'>
                <Typography variant="h3">App Not Found</Typography>
            </div>
        )
    }

    return (
        <div className="container w-full h-full p-4">
            <div className='flex flex-col w-full text-center justify-center p-6'>
                <div className='flex flex-row w-full justify-center'>
                    <Typography variant="h2">{localMyApp.name || "Loading..."}</Typography>
                </div>
                <div className='flex flex-row w-full justify-center'>
                    <Typography variant="h6">{localMyApp.purpose || "Loading..."}</Typography>
                </div>

                <div className='flex flex-col w-full p-6'>
                    {myApp && myApp.features !== undefined && Object.keys(myApp.features).includes('map') && (
                        <div className='flex flex-col w-full border-b-2 p-4'>
                            <Typography variant="h5" className=''>Map</Typography>

                            {mapPositions && mapPositions.length === 0 && (
                                <div className='flex flex-col w-full p-4 border-2 mb-2'>
                                    <Typography variant="h6" className='text-gray-500'>No map positions found</Typography>
                                </div>
                            )}

                            {mapPositions && mapPositions.map((position) => (
                                <div className='flex flex-col w-full p-4 border-2 mb-2' key={position.id}>
                                    <div className='flex flex-col'>
                                        {position.latitude}
                                    </div>
                                    <div className='flex flex-col'>
                                        {position.longitude}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {myApp && myApp.features !== undefined && Object.keys(myApp.features).includes('camera') && (
                        <div className='flex flex-col w-full border-b-2 p-4'>
                            <Typography variant="h5" className=''>Camera</Typography>

                            {appPhotos && appPhotos.length === 0 && (
                                <div className='flex flex-col w-full p-4 border-2 mb-2'>
                                    <Typography variant="h6" className='text-gray-500'>No photos found</Typography>
                                </div>
                            )}

                            {appPhotos && appPhotos.map((photo) => (
                            <div className='flex flex-col w-full p-4 border-2 mb-2' key={photo.id}>
                                <div className='flex flex-col'>
                                    {photo.url}
                                </div>
                            </div>
                        ))}
                        </div>
                    )}

                    {myApp && myApp.features !== undefined && Object.keys(myApp.features).includes('chat') && (
                        <div className='flex flex-col w-full border-b-2 p-4'>
                            <Typography variant="h5" className=''>Chat</Typography>

                            {chatMessages && chatMessages.length === 0 && (
                                <div className='flex flex-col w-full p-4 border-2 mb-2'>
                                    <Typography variant="h6" className='text-gray-500'>No chat messages found</Typography>
                                </div>
                            )}

                            {chatMessages && chatMessages.map((message) => (
                                <div className='flex flex-col w-full p-4 border-2 mb-2' key={message.id}>
                                    <div className='flex flex-col'>
                                        {message.text}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {myApp && myApp.features !== undefined && Object.keys(myApp.features).includes('calendar') && (
                        <div className='flex flex-col w-full border-b-2 p-4'>
                            <Typography variant="h5" className=''>Calendar</Typography>

                            {calendarEvents && calendarEvents.length === 0 && (
                                <div className='flex flex-col w-full p-4 border-2 mb-2'>
                                    <Typography variant="h6" className='text-gray-500'>No calendar events found</Typography>
                                </div>
                            )}

                            {calendarEvents && calendarEvents.map((event) => (
                                <div className='flex flex-col w-full p-4 border-2 mb-2' key={event.id}>
                                    <div className='flex flex-col'>
                                        {event.month}/{event.day}/{event.year}
                                    </div>
                                    <div className='flex flex-col'>
                                        {event.startHour}:{event.startMinute} - {event.endHour}:{event.endMinute}
                                    </div>
                                    <div className='flex flex-col'>
                                        {event.timeZone}
                                    </div>
                                    <div className='flex flex-col pt-4 font-bold'>
                                        {event.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <Typography variant="h6" className='text-gray-100'>{localId || "No ID"}</Typography>

            </div>
        </div>
    );
}