import React, { useState, useEffect } from 'react'
import {
    // Button,
    // Checkbox,
    Typography,
    // Input,
    // List,
    // ListItem,
    // Card,
    // Carousel,
    // CardBody,
} from "@material-tailwind/react";

import LoadingIndicator from '../LoadingIndicator';

import ChatAgent from '../chat/ChatAgent';
import CalendarAgent from '../calendar/CalendarAgent';
import MapAgent from '../map/MapAgent';
import RegularButton from '../buttons/RegularButton';

export default function AgentContent({ currentUser, activeAgent, loading }) {

    console.log('activeAgent: ', activeAgent);

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <div className='flex flex-row md:flex-col mt-2'>
            <RegularButton url={"/agents"} text="Back" />
            <Typography variant='h1'>
                {activeAgent.name}
            </Typography>

            {/* Display chat */}
            <ChatAgent activeAgent={activeAgent} />

            {/* Display calendar */}
            <CalendarAgent activeAgent={activeAgent} />

            {/* Display map */}
            <MapAgent activeAgent={activeAgent} />
        </div>
    );
}