import React, { useEffect, useState } from "react";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

import General from "../layouts/General";

import appstorepreview1 from '../images/appstorepreview1.png';
import appstorepreview2 from '../images/appstorepreview2.png';
import appstorepreview3 from '../images/appstorepreview3.png';
import appstorepreview4 from '../images/appstorepreview4.png';
import appstorepreview5 from '../images/appstorepreview5.png';
import appstorepreview6 from '../images/appstorepreview6.png';
import appstorepreview6b from '../images/appstorepreview6b.png';
import appstorepreview7 from '../images/7.png';
import appstorepreview8 from '../images/8.png';
import appstorepreview9 from '../images/9.png';
import appstorepreview10 from '../images/10.png';
import appstorepreview11 from '../images/appstorepreview11.png';
import appstorepreview12 from '../images/appstorepreview12.png';
import appstorepreview13 from '../images/appstorepreview13.png';
import appstorepreview14 from '../images/appstorepreview14.png';
import appstorepreview15 from '../images/appstorepreview15.png';
import appstorepreview16 from '../images/appstorepreview16.png';
import appstorepreview17 from '../images/appstorepreview17.png';
import appstorepreview18 from '../images/appstorepreview18.png';
import appstorepreview19 from '../images/appstorepreview19.png';
import appstorepreview20 from '../images/appstorepreview20.png';
import appstorepreview21 from '../images/appstorepreview21.png';
import appstorepreview22 from '../images/appstorepreview22.png';

const firebaseConfig = {
  apiKey: "AIzaSyAm-Rxh3eQrAoqIWhTZZw5vKNDk8Bcdeko",
  authDomain: "recommengine-48d51.firebaseapp.com",
  databaseURL: "https://recommengine-48d51-default-rtdb.firebaseio.com",
  projectId: "recommengine-48d51",
  storageBucket: "recommengine-48d51.appspot.com",
  messagingSenderId: "956851828912",
  appId: "1:956851828912:web:0133ecfa1824f0cc542375",
  measurementId: "G-HE62CXXNCP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export function RegistrationForm({ signIn, signOut }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [currentForm, setCurrentForm] = useState('register');
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentForm === 'register') {
      await registerWithEmailAndPassword(name, email, password);
    } else {
      await logInWithEmailAndPassword(email, password).then(() => {
        window.location = '/dashboard';
      });
    }
  };

  useEffect(() => {
    if (signOut) {
      logout();
      setCurrentForm('login');
    }
    if (signIn) {
      setCurrentForm('login');
    }
  });

  return (
    <General>
      <div className='flex flex-col mt-2'>
        <div className='flex flex-col ml-auto mr-auto'>
          {currentForm === 'register' && (
            <>
              <div className='flex flex-col w-full h-full text-center p-4 text-black'>
                <Typography variant='h1' className='ml-4'>
                  Create unlimited apps based around camera, location, calendar, and chat!
                </Typography>
                <div className='flex flex-wrap justify-center p-4'>
                  <Typography variant='h4' className='ml-4 underline text-black'>
                    <a href="https://testflight.apple.com/join/xes7Lx3R" target="_blank" rel="noopener noreferrer" className="ml-4">Join the beta on Apple TestFlight today!</a>
                  </Typography>
                </div>
                <div className='flex flex-wrap justify-center p-4'>
                  <img src={appstorepreview6} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview14} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview15} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview16} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview17} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview2} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview3} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview1} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview4} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview5} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview6b} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview7} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview8} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview9} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview10} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview11} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview12} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview13} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview21} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview22} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview18} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview19} className="h-auto w-full max-w-xs p-2" />
                  <img src={appstorepreview20} className="h-auto w-full max-w-xs p-2" />
                </div>
                <div className='flex flex-wrap justify-center p-4'>
                  <Typography variant='h4' className='ml-4 underline text-green-500'>
                    <a href="https://testflight.apple.com/join/xes7Lx3R" target="_blank" rel="noopener noreferrer" className="ml-4">Join the beta!</a>
                  </Typography>
                </div>
              </div>
              {/* <Typography color="gray" className="mt-4 text-center font-normal">
                Already registered?{" "}
                <a href="#" onClick={() => setCurrentForm('login')} className="font-medium text-gray-900">
                  Sign In
                </a>
              </Typography> */}
            </>
          )}

          {currentForm === 'login' && (
            <Card color="transparent" shadow={false} className="w-full ml-auto mr-auto mt-20 mb-10">
              <Typography variant="h1" color="blue-gray">
                Sign In
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                Welcome back!
              </Typography>
              <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    Your Email
                  </Typography>
                  <Input
                    size="lg"
                    placeholder="name@mail.com"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) => setEmail(e.target.value)}
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    Password
                  </Typography>
                  <Input
                    type="password"
                    size="lg"
                    placeholder="********"
                    onChange={(e) => setPassword(e.target.value)}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                
                <Button className="mt-6" fullWidth onClick={handleSubmit}>
                  Sign In
                </Button>
                <Typography color="gray" className="mt-4 text-center font-normal">
                  Don't have an account?{" "}
                  <a href="#" onClick={() => setCurrentForm('register')} className="font-medium text-gray-900">
                    Register
                  </a>
                </Typography>
              </form>
            </Card>
          )}
        </div>
      </div>
    </General>
  );
}