import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel
} from "@material-tailwind/react";

import Header from './Header'
import { Footer } from './Footer'

function AboutUs() {
    return (
        <div className='flex flex-col p-2 md:p-2 w-full h-full'>
            <Header />
            <div className='flex flex-row md:flex-col w-full h-full text-center p-10'>
                <div>
                    <Typography variant='h4' className='ml-4'>Become an Affiliate</Typography>
                    
                    <div className='flex flex-col w-full h-full p-10'>
                        <Typography variant='p' className='ml-4'>Interested in becoming an affiliate? Get in contact with us.</Typography>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default AboutUs