import React, { useState, useEffect } from 'react';
import { Button, Typography, Input } from "@material-tailwind/react";
import Header from './Header';
import { Footer } from './Footer';
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from '../FirebaseConfig';  // Update the path to your FirebaseConfig file if necessary

function Businesses() {
    const [business, setBusiness] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        category: '',
        description: ''
    });

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        // Monitor authentication state
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsUserLoggedIn(!!user);
        });

        // Clean up subscription on unmount
        return () => unsubscribe();
    }, []);

    const handleChange = (e) => {
        setBusiness({ ...business, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!auth.currentUser) {
            alert("You must be logged in to add a business.");
            return;
        }

        try {
            const userId = auth.currentUser.uid;
            const docRef = await addDoc(collection(db, "recommengine/users/" + userId + "/data/businesses"), {
                ...business,
                userId: userId,
                createdAt: new Date().toISOString(),
            });

            if (docRef) {
                alert("Business added successfully!");
                setBusiness({
                    name: '',
                    phoneNumber: '',
                    email: '',
                    website: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    category: '',
                    description: ''
                });
            }
        } catch (e) {
            console.error("Error adding business: ", e);
            alert("Error adding business. Please try again.");
        }
    };

    return (
        <div className='flex flex-col p-2 md:p-2 w-full h-full'>
            <Header />
            <div className='flex flex-col md:flex-row w-full h-full text-center p-4 md:p-10'>
                <div className='w-full'>
                    <Typography variant='h4' className='ml-0 md:ml-4'>Business Directory</Typography>
        
                    <div className='flex flex-col w-full h-full p-4 md:p-10'>
                        <Typography variant='h2' className='ml-0 md:ml-4'>Add a business</Typography>
        
                        <div className='flex flex-col w-full h-full p-4 md:p-10 space-y-4'>
                            <Input 
                                label="Name" 
                                className="w-full" 
                                name="name" 
                                value={business.name} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Phone Number" 
                                className="w-full" 
                                name="phoneNumber" 
                                value={business.phoneNumber} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Email" 
                                className="w-full" 
                                name="email" 
                                value={business.email} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Website" 
                                className="w-full" 
                                name="website" 
                                value={business.website} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Address" 
                                className="w-full" 
                                name="address" 
                                value={business.address} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="City" 
                                className="w-full" 
                                name="city" 
                                value={business.city} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="State" 
                                className="w-full" 
                                name="state" 
                                value={business.state} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Zip" 
                                className="w-full" 
                                name="zip" 
                                value={business.zip} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Category" 
                                className="w-full" 
                                name="category" 
                                value={business.category} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Description" 
                                className="w-full" 
                                name="description" 
                                value={business.description} 
                                onChange={handleChange} 
                            />
                        </div>
        
                        {!isUserLoggedIn && (
                            <Typography color="red" className='ml-0 md:ml-4'>
                                You must be logged in to add a business.
                            </Typography>
                        )}
        
                        <Button 
                            className='ml-0 md:ml-4 mt-4 md:mt-0' 
                            onClick={handleSubmit} 
                            disabled={!isUserLoggedIn}
                        >
                            Add Business
                        </Button>
                    </div>
                </div>
            </div>
        
            <Footer />
        </div>
    );
}

export default Businesses;