import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel } from "@material-tailwind/react";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

// import Header from './Header'
// import { Footer } from './Footer'
// import NavigationLinks from './NavigationLinks'
// import LoadingIndicator from './LoadingIndicator';
import General from '../layouts/General';
import AppsContent from './AppsContent';

import { db, auth } from '../FirebaseConfig'

const getMyApps = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/"+ userId +"/data/apps"))
    );

    const apps = [];

    querySnapshot.forEach((doc) => {
        // console.log('an app found: ' + doc.data().name);
        apps.push({ id: doc.id, ...doc.data()});
    });

    // console.log(apps);
    return apps.sort((a, b) => (a.name > b.name) ? 1 : -1);
}

export default function Apps() {
    const [myApps, setMyApps] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                getMyApps(user.uid).then((apps) => {
                    setMyApps(apps);
                })
                setLoading(false);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    return (
        <General title="Apps">
            <AppsContent currentUser={currentUser} myApps={myApps} />
        </General>
    )
}