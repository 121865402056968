import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel } from "@material-tailwind/react";
// import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

import Header from '../components/Header'
import { Footer } from '../components/Footer'
import NavigationLinks from '../components/NavigationLinks'
import LoadingIndicator from '../components/LoadingIndicator';

import { db, auth } from '../FirebaseConfig'

export default function General({ title, children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                setLoading(false);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })
    }, [])

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <div className='flex flex-col p-2 md:p-2 w-full'>
            <Header />
            {/* <div className='flex flex-row md:flex-col'>
                <div className='flex flex-row'>
                    <div>
                        <Typography variant='h3' className='ml-4'>{title}</Typography>
                    </div>
                </div>
            </div> */}

            {children}

            <Footer />
        </div>
    );
}