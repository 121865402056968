import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel
  } from "@material-tailwind/react";
  
  import Header from './Header';
  import { Footer } from './Footer';
  
  import appstorepreview1 from '../images/appstorepreview1.png';
  import appstorepreview2 from '../images/appstorepreview2.png';
  import appstorepreview3 from '../images/appstorepreview3.png';
  import appstorepreview4 from '../images/appstorepreview4.png';
  import appstorepreview5 from '../images/appstorepreview5.png';
  import appstorepreview6 from '../images/appstorepreview6.png';
  import appstorepreview6b from '../images/appstorepreview6b.png';
  import appstorepreview7 from '../images/7.png';
  import appstorepreview8 from '../images/8.png';
  import appstorepreview9 from '../images/9.png';
  import appstorepreview10 from '../images/10.png';

  function AboutUs() {
    return (
      <div className='flex flex-col p-2 w-full h-full'>
        <Header />
        <div className='flex flex-col w-full h-full text-center p-4'>
          <div>
            {/* <Typography variant='h4' className='ml-4'>About Us</Typography> */}
            
            <Typography variant='h1' className='ml-4'>
              Create unlimited apps based around camera, location, calendar, and chat!
            </Typography>
  
            <div className='flex flex-wrap justify-center p-4'>
              <img
                src={appstorepreview6}
                className="h-auto w-full max-w-xs p-2"
              /> 
              <img
                src={appstorepreview2}
                className="h-auto w-full max-w-xs p-2"
              />
              <img
                src={appstorepreview3}
                className="h-auto w-full max-w-xs p-2"
              />
            </div>
            <div className='flex flex-wrap justify-center p-4'>
              <img
                src={appstorepreview1}
                className="h-auto w-full max-w-xs p-2"
              />
              <img
                src={appstorepreview4}
                className="h-auto w-full max-w-xs p-2"
              /> 
              <img
                src={appstorepreview5}
                className="h-auto w-full max-w-xs p-2"
              /> 
              <img
                src={appstorepreview6b}
                className="h-auto w-full max-w-xs p-2"
              />
              <img
                src={appstorepreview7}
                className="h-auto w-full max-w-xs p-2"
              /> 
              <img
                src={appstorepreview8}
                className="h-auto w-full max-w-xs p-2"
              /> 
              <img
                src={appstorepreview9}
                className="h-auto w-full max-w-xs p-2"
              /> 
              <img
                src={appstorepreview10}
                className="h-auto w-full max-w-xs p-2"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  
  export default AboutUs;