import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input } from "@material-tailwind/react";

import { collection, addDoc } from "firebase/firestore";


import Header from './Header';
import { Footer } from './Footer';
import recommengineFeatureDescriptions from './recommengineFeatureDescriptions';
import NavigationLinks from './NavigationLinks';

import { db, auth } from '../FirebaseConfig'

async function registerApp(appName, appPackageName, appFeatures) {
    try {
        let userId = auth.currentUser.uid
        const docRef = await addDoc(collection(db, "recommengine/users/"+ userId +"/data/apps"), {
            name: appName,
            packageName: appPackageName,
            features: appFeatures,
            userId: auth.currentUser.uid,
            createdAt: new Date().toISOString(),
        }).catch((error) => {
            console.error("Error adding document: ", error);
            return false
        })

        return docRef
    } catch (e) {
        console.error("Error adding document: ", e);
        return false
    }
}
async function saveApp(appName, appPackageName, appFeatures) {
    console.log('save app');
    return registerApp(appName, appPackageName, appFeatures)
        .then((result) => {
            console.log(result);
            return result;
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            return false;
        });
}

export function AppGenerator({ statusMessage }) {
    const [currentStep, setCurrentStep] = useState(1)
    const [step, setStep] = useState(1)
    const [appName, setAppName] = useState("")
    const [appPackageName, setAppPackageName] = useState("")
    const [appFeatures, setAppFeatures] = useState({
        "map": false,
        "chat": false,
        "camera": false,
        "calendar": false,
        "pushNotifications": false,
        "onboardingQuestions": false,
        "userProfile": false,
        "social": false,
    })
    const [appFeaturesDescription, setAppFeaturesDescription] = useState([])
    const [errors, setErrors] = useState([])  

    const validateForm = () => {
        if (appName && appPackageName) {
            return true
        } else {
            setErrors(["Name and Package Name are required"])
            return false
        }
    }

    const proceed = (step) => {
        console.log('proceed from current step ' + currentStep + ' to step ' + step)
        switch(currentStep) {
            case 1:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break
            case 2:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break;
            case 3:
                if (validateForm()) {
                    console.log('validated')
                    setErrors([])

                    new Promise(function(resolve) {
                        resolve(saveApp(appName, appPackageName, appFeatures))
                    }).then((result) => {
                        console.log('received result from step 3: ' + result)
                        if (result.id) {
                            console.log('app saved: ' + result.id)
                            setStep(step + 1)
                            setCurrentStep(step + 1)
                        }
                    }).catch((error) => {
                        console.error("Error adding document: ", error);
                    })
                    // saveApp(appName, appPackageName, appFeatures).then
                    // ((result) => {
                    //     console.log('received result from step 3: ' + result)
                    //     if (result) {
                    //         console.log('app saved')
                    //         setStep(step)
                    //         setCurrentStep(step)
                    //     }
                    // })
                }
                break;
            default:
                setStep(step)
                setCurrentStep(step)
        }
    }
    
    const goDashboard = () => {
        // redirect to /dashboard
        window.location.href = '/dashboard'
    }

    const storeFeature = (event, feature) => {
        // console.log('event: ' + event.target.checked)
        // console.log('feature: ' + feature)

        // setAppFeatures({ ...appFeatures, feature: !appFeatures.feature })
        if (event.target.checked) {
            setAppFeatures({ ...appFeatures, [feature]: true })   
        }  else {
            setAppFeatures({ ...appFeatures, [feature]: false })
        }

        // Group feature and description
        const featureDescriptions = recommengineFeatureDescriptions;

        // Find the feature description
        try {
            const featureDescription = featureDescriptions.find(f => f.name === feature).description

            if (event.target.checked) {
                setAppFeaturesDescription([...appFeaturesDescription, featureDescription])
                // console.log('Feature description: ' + featureDescription)
            } else {
                setAppFeaturesDescription(appFeaturesDescription.filter(f => f !== featureDescription))
            }
        } catch (error) {
            console.log(error)
        }   
    }

    return (
        <div className='flex flex-col w-full h-full p-2 md:p-2'>
            <Header />

            <div className='flex flex-col md:flex-row w-full'>
                {/* <div>
                    <Typography variant='h4' className='ml-4'>
                        Create New App
                    </Typography>

                </div> */}

            {step === 1 && (
                <div className='flex flex-col mt-6 md:mt-2'>
                    <div className=''>
                        <Typography variant='h3'>Name of the app</Typography>
                    </div>

                    <hr/>

                    <div className='p-2 md:p-10'>
                        <div className='w-1/6'>
                            <Input variant="outlined" label="Name" placeholder="Name" value={appName} onChange={(e) => setAppName(e.target.value)}/>
                        </div>
                        
                        <div className='w-1/6 mt-2'>
                            <Input variant="outlined" label="Package Name" placeholder="Package Name" value={appPackageName} onChange={(e) => setAppPackageName(e.target.value)} />
                        </div>

                        <div className='w-3/6 pt-4'>
                            {/* List errors */}
                            {errors.map((error, index) => (
                                <Typography color="red" key={index}>{error}</Typography>
                            ))}
                        </div>

                        <div className='p-2 md:p-10'>
                            <Button onClick={() => proceed(2)}>Continue</Button>
                        </div>
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className='flex flex-col mt-6 md:mt-2'>
                    <div>
                        <Typography variant='h2'>Available Features</Typography>
                    </div>

                    <hr/>

                    <div className='p-2 md:p-10'>
                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Map
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        📍 Show positions on a map.
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "map")}
                                checked={appFeatures.map}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                    <Typography color="blue-gray" className="font-medium">
                                        Chat
                                    </Typography>
                                    <Typography variant="small" color="gray" className="font-normal">
                                    💬 Allow chat with AI or with other users.
                                    </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "chat")}
                                checked={appFeatures.chat}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                    <Typography color="blue-gray" className="font-medium">
                                        Camera
                                    </Typography>
                                    <Typography variant="small" color="gray" className="font-normal">
                                    📷 Allow camera access
                                    </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "camera")}
                                checked={appFeatures.camera}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                        Calendar
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        📅 Display a calendar
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "calendar")}
                                checked={appFeatures.calendar}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Social
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        👥 Allow for friending and presence capabilities
                                        </Typography>
                                    </div>
                                }
                                onChange={() => setAppFeatures({ ...appFeatures, social: !appFeatures.social })}
                                checked={appFeatures.social}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Push Notifications
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        🛎️ Allow for push notifications
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "pushNotifications")}
                                checked={appFeatures.pushNotifications}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Onboarding Questions
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                            🗒️ Require onboarding questions or create quiz
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "onboardingQuestions")}
                                checked={appFeatures.onboardingQuestions}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            User profile
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        👤 Allow for user profile
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "userProfile")}
                                checked={appFeatures.userProfile}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Custom Feature
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        🤖 Add custom feature
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "customFeature")}
                                checked={appFeatures.customFeature}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>
                        

                        <div className='p-2 md:p-10'>
                            <Button variant='outlined' onClick={() => setStep(1)}>Go Back</Button>
                            <Button className='ml-2' onClick={() => proceed(3)}>Continue</Button>
                        </div>
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className='flex flex-col mt-6 md:mt-2'>
                    <div>
                        <Typography variant='h2'>Summary</Typography>
                    </div>

                    <hr/>

                    <div className='p-2 md:p-10'>
                        <Typography color="blue-gray" className="font-bold">
                        App Name
                        </Typography>
                        <Typography color="blue-gray" className="font-medium">
                            {appName}
                        </Typography>

                        <Typography color="blue-gray" className="font-bold">
                            Package Name
                        </Typography>

                        <Typography color="blue-gray" className="font-medium">
                            {appPackageName}
                        </Typography>

                        <Typography color="blue-gray" className="font-bold">
                            Features
                        </Typography>

                        {(appFeaturesDescription).map(feature => (
                            <div>
                                <Typography color="blue-gray" className="font-medium" key={feature}>
                                    {feature}
                                </Typography>
                            </div>
                        ))}

                        <div className='p-2 md:p-10'>
                            <Button variant='outlined' onClick={() => setStep(2)}>Go Back</Button>
                            <Button className='ml-2' onClick={() => proceed(3)}>Save and Continue</Button>
                        </div>
                    </div>
                </div>
            )}

            {step === 4 && (
                <div className='flex flex-col m-6 md:mt-2 w-full'>
                    <div className='w-full text-center p-2 md:p-10'>
                        <Typography variant='h1'>All Done!</Typography>

                        <Button className='ml-2' onClick={() => goDashboard()}>Go to dashboard</Button>
                    </div>
                </div>
            )}

            </div>

            <Footer />
        </div>
    );
}