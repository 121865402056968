import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Typography, Input, Card } from "@material-tailwind/react";

import Header from './Header';
import { Footer } from './Footer';
import recommengineFeatureDescriptions from './recommengineFeatureDescriptions';
// console.log(recommengineFeatureDescriptions);

function About() {

    return (
      <div className='flex flex-col w-full h-full p-10'>
        <Header />

        <Typography variant="h1" color="blue-gray">
            About Us
        </Typography>
        
        <Card color="transparent" shadow={false} className="w-1/2 pt-10 pb-10 ml-auto mr-auto">
            <Typography variant="h4" color="blue-gray">
                What is Recommengine?
            </Typography>
            <Typography color="blue-gray">
                Recommengine is a recommendation engine that helps you plan how to most effectively live life with everything you've got going on.
            </Typography>
        </Card>

        <Card color="transparent" shadow={false} className="w-1/2 pt-10 pb-10 ml-auto mr-auto">
            <Typography variant="h4" color="blue-gray">
                What are the benefits of using Recommengine?
            </Typography>
            <Typography color="blue-gray">
                Recommengine allows you to build your own personalized recommendation engines that fit your needs.
            </Typography>
        </Card>

        <Card color="transparent" shadow={false} className="w-1/2 pt-10 pb-10 ml-auto mr-auto">
            <Typography variant="h4" color="blue-gray">
                What are some of the features of Recommengine?
            </Typography>

            <div className='flex flex-col'>
                {recommengineFeatureDescriptions.map((featureDescription, index) => (
                    <Card shadow={false} key={index} className='p-2 w-full mb-4 text-center'>
                        <Typography variant="h6" color="blue-gray">
                            {featureDescription.displayName}
                        </Typography>
                        <Typography>
                            {featureDescription.description}
                        </Typography>

                    </Card>
                ))}
            </div>
        </Card>

        <Card color="transparent" shadow={false} className="w-1/2 pt-10 pb-10 ml-auto mr-auto">
            <Typography variant="h4" color="blue-gray">
                How much does it cost to use Recommengine?
            </Typography>
            <Typography color="blue-gray">
                $20 per month per app
            </Typography>
        </Card>

        <Card color="transparent" shadow={false} className="w-1/2 pt-10 pb-10 ml-auto mr-auto">
            <Typography variant="h4" color="blue-gray">
                Do custom Recommengines expire?
            </Typography>
            <Typography color="blue-gray">
                Custom Recommengines will expire at the end of the billing cycle. It will remain inactive for 1 month before being deleted.
            </Typography>
        </Card>

        <Footer />
      </div>
  );
}

export default About;
