
const recommengineFeatureDescriptions = [
    {
        "name": "map",
        "displayName": "Map",
        "description": "📍 Show positions on a map.",
    },
    {
        "name": "chat",
        "displayName": "Chat",
        "description": "💬 Allow chat with AI or with other users.",
    },
    {
        "name": "camera",
        "displayName": "Camera",
        "description": "📷 Allow camera access",
    },
    {
        "name": "calendar",
        "displayName": "Calendar",
        "description": "📅 Display a calendar",
    },
    {
        "name": "social",
        "displayName": "Social",
        "description": "👥 Allow for friending and presence capabilities",
    },
    {
        "name": "pushNotifications",
        "displayName": "Push Notifications",
        "description": "🛎️ Allow for push notifications",
    },
    {
        "name": "onboardingQuestions",
        "displayName": "Onboarding Questions",
        "description": "🗒️ Require onboarding questions or create quiz",
    },
    {
        "name": "userProfile",
        "displayName": "User Profile",
        "description": "👤 Allow for user profile",
    },
    {
        "name": "customFeature",
        "displayName": "Custom Feature",
        "description": "🤖 Add custom feature",
    }
]

export default recommengineFeatureDescriptions