import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import {
    getAuth,
} from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyAm-Rxh3eQrAoqIWhTZZw5vKNDk8Bcdeko",
    authDomain: "recommengine-48d51.firebaseapp.com",
    databaseURL: "https://recommengine-48d51-default-rtdb.firebaseio.com",
    projectId: "recommengine-48d51",
    storageBucket: "recommengine-48d51.appspot.com",
    messagingSenderId: "956851828912",
    appId: "1:956851828912:web:0133ecfa1824f0cc542375",
    measurementId: "G-HE62CXXNCP"
  };

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);