import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel
  } from "@material-tailwind/react";
  
  import Header from './Header';
  import { Footer } from './Footer';
import { Link } from "react-router-dom";
  
  function Contact() {
    return (
      <div className='flex flex-col p-2 w-full h-full'>
        <Header />
        <div className='flex flex-col w-full h-full text-center p-4'>
          <div>
            <Typography variant='h4' className='ml-4'>Contact Us</Typography>
            {/* <Typography variant='h1' className='ml-4'>We're here to help.</Typography>
            <Typography className='ml-4'>Whether you have a question about our product or need support, we're here to help.</Typography> */}
  
            <div className='flex flex-col w-full h-full p-4'>
              {/* <Card color="transparent" shadow={false} className="w-full max-w-lg pt-10 pb-10 mx-auto">
                <div className="p-2">
                  <Input label="Email" className="w-full" />
                </div>
                <div className="p-2">
                  <Input label="Subject" className="w-full" />
                </div>
                <div className="p-2">
                  <Input label="Message" rows={5} className="w-full" />
                </div>
                <Button className="mt-6" fullWidth color="black">
                  Send
                </Button>
              </Card> */}

                <div className="p-20">
                  <Typography variant="h6">We'd love to hear from you.</Typography>
                  <br />
                  <Link to="mailto:brian@bcpsolutionsllc.com" className="ml-4">brian@bcpsolutionsllc.com</Link>
                  <br />
                  <br />
                </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  
  export default Contact;