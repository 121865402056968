import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel, CardBody } from "@material-tailwind/react";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

import Header from './Header'
import { Footer } from './Footer'
import NavigationLinks from './NavigationLinks'
import LoadingIndicator from './LoadingIndicator';
import { db, auth } from '../FirebaseConfig'

import General from '../layouts/General'
import DashboardContent from './DashboardContent';

const getMyApps = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/"+ userId +"/data/apps"))
    );

    const apps = [];

    querySnapshot.forEach((doc) => {
        apps.push(doc.data());
    });

    // console.log(apps);
    return apps;
}

const getMyAgents = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/" + userId + "/data/agents"))
    );

    const agents = [];
    querySnapshot.forEach((doc) => {
        agents.push(doc.data());
    });

    return agents;
}

const getMyQuizzes = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/" + userId + "/data/quizzes"))
    );

    const quizzes = [];
    querySnapshot.forEach((doc) => {
        quizzes.push(doc.data());
    });

    return quizzes;
}

export default function Dashboard() {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [myApps, setMyApps] = useState([]);
    const [myAgents, setMyAgents] = useState([]);
    const [myQuizzes, setMyQuizzes] = useState([]);

    useEffect(() => {

        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                getMyApps(user.uid).then((apps) => {
                    setMyApps(apps);
                })
                getMyAgents(user.uid).then((agents) => {
                    setMyAgents(agents);
                })
                getMyQuizzes(user.uid).then((quizzes) => {
                    setMyQuizzes(quizzes);
                })
                setLoading(false);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    return (
        <General title="Dashboard">
            <DashboardContent currentUser={currentUser} myApps={myApps} myAgents={myAgents} myQuizzes={myQuizzes} />
        </General>
    )
}