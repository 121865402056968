import React, { useEffect, useState } from "react";

import logo from '../images/logo-simple-white-v2.png';

import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  // Bars4Icon,
  // GlobeAmericasIcon,
  NewspaperIcon,
  // PhoneIcon,
  // RectangleGroupIcon,
  SquaresPlusIcon,
  SunIcon,
  // TagIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";

import { initializeApp } from "firebase/app";
import {
 GoogleAuthProvider,
 getAuth,
 signInWithPopup,
 signInWithEmailAndPassword,
 createUserWithEmailAndPassword,
 sendPasswordResetEmail,
 signOut,
} from "firebase/auth";

import {
 getFirestore,
 query,
 getDocs,
 collection,
 where,
 addDoc,
} from "firebase/firestore";

import NavigationLinks from "./NavigationLinks";

const firebaseConfig = {
  apiKey: "AIzaSyAm-Rxh3eQrAoqIWhTZZw5vKNDk8Bcdeko",
  authDomain: "recommengine-48d51.firebaseapp.com",
  databaseURL: "https://recommengine-48d51-default-rtdb.firebaseio.com",
  projectId: "recommengine-48d51",
  storageBucket: "recommengine-48d51.appspot.com",
  messagingSenderId: "956851828912",
  appId: "1:956851828912:web:0133ecfa1824f0cc542375",
  measurementId: "G-HE62CXXNCP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const navListMenuItems = [
  {
    title: "Products",
    description: "Find the perfect solution for your needs.",
    icon: SquaresPlusIcon,
    href: "/products",
  },
  {
    title: "Services",
    description: "Learn how to leverage Recommengine",
    icon: SunIcon,
    href: "/services",
  },
  {
    title: "News",
    description: "Read insightful articles, tips, and expert opinions.",
    icon: NewspaperIcon,
    href: "/news",
  },
  {
    title: "About Us",
    description: "Meet the team",
    icon: UserGroupIcon,
    href: "/about-us",
  },
  // {
  //   title: "Blog",
  //   description: "Find the perfect solution for your needs.",
  //   icon: Bars4Icon,
  // },
  // {
  //   title: "Support",
  //   description: "Reach out to us for assistance or inquiries",
  //   icon: GlobeAmericasIcon,
  // },
  // {
  //   title: "Contact",
  //   description: "Find the perfect solution for your needs.",
  //   icon: PhoneIcon,
  // },
  // {
  //   title: "Special Offers",
  //   description: "Explore limited-time deals and bundles",
  //   icon: TagIcon,
  // },
];
 
function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  // const [loggedIn, setLoggedIn] = useState(false)

  const renderItems = navListMenuItems.map(
    ({ icon, title, description, href }, key) => (
      <a href={href} key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm font-bold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    ),
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
      >
        {/* <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Resources
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler> */}
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
 
function NavList({loggedIn, currentUserEmail}) {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">

      {currentUserEmail && (
          <NavigationLinks />
      )}

      {loggedIn && (
          <Typography
            as="a"
            href="/dashboard"
            variant="small"
            color="blue-gray"
            className="font-medium text-sm"
          >
            <ListItem className="flex items-center gap-2 py-2 pr-4">{currentUserEmail}</ListItem>
          </Typography>
      )}

      <Typography
        as="a"
        href="/businesses"
        variant="small"
        color="blue-gray"
        className="font-medium text-sm"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">Are You a Local Business?</ListItem>
      </Typography>
      
      {!!!loggedIn && (
        <Typography
          as="a"
          href="/register"
          variant="small"
          color="blue-gray"
          className="font-medium text-sm"
        >
          <ListItem className="flex items-center gap-2 py-2 pr-4">Register</ListItem>
        </Typography>
      )}

      {!!!loggedIn && (
        <Typography
          as="a"
          href="/sign-in"
          variant="small"
          color="blue-gray"
          className="font-medium text-sm"
        >
          <ListItem className="flex items-center gap-2 py-2 pr-4">Sign In</ListItem>
        </Typography>
      )}

      {/* <Typography
        as="a"
        href="/dashboard"
        variant="small"
        color="blue-gray"
        className="font-medium text-sm"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">Dashboard</ListItem>
      </Typography> */}

      {/* <Typography
        as="a"
        href="/create-new-app"
        variant="small"
        color="blue-gray"
        className="font-medium text-sm"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">Create New App</ListItem>
      </Typography> */}
      
      {/* 
      <Typography
        as="a"
        href="/view-ios-apps"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">View iOS Apps</ListItem>
      </Typography>

      <Typography
        as="a"
        href="#"
        variant="small"
        color="blue-gray"
        className="font-medium text-sm"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">View Android Apps</ListItem>
      </Typography>
       */}

      {loggedIn && (
        <Typography
          as="a"
          href="/sign-out"
          variant="small"
          color="blue-gray"
          className="font-medium text-sm"
        >
          <ListItem className="flex items-center gap-2 py-2 pr-4">Sign Out</ListItem>
        </Typography>
      )}

      <NavListMenu />
      <Typography
        as="a"
        href="/contact"
        variant="small"
        color="blue-gray"
        className="font-medium text-sm"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Contact Us
        </ListItem>
      </Typography>
    </List>
  );
}
 
function Header({}) {
  const [openNav, setOpenNav] = React.useState(false);
  const [loggedIn, setLoggedIn] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [currentUserEmail, setCurrentUserEmail] = useState(null)
  
  // Check if user is logged in, set state accordingly
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setLoggedIn(true);
        // setCurrentUser(user);
        // console.log(user.email)
        setCurrentUserEmail(user.email)
      } else {
        setLoggedIn(false);
        // setCurrentUser(null);
        setCurrentUserEmail(null)
      }
    })
  })
  
  return (
    <Navbar className="mx-auto w-full px-4 py-2 mb-2">
      <div className="flex items-center justify-between text-blue-gray-900">

        <a href="/"><img
          src={logo}
          className="h-auto w-80"
        /></a>


        <div className="hidden lg:block">
          <NavList loggedIn={loggedIn} currentUserEmail={currentUserEmail} />
        </div>

        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList loggedIn={loggedIn} currentUserEmail={currentUserEmail} />
      </Collapse>
    </Navbar>
  );
}

export default Header;