import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Typography, Input } from "@material-tailwind/react";
import { getFirestore, collection, addDoc, query, where, getDocs, doc, getDoc, } from "firebase/firestore";

// import Header from './Header'
// import { Footer } from './Footer'
// import NavigationLinks from './NavigationLinks'

import General from "../layouts/General";
import AppProjectContent from "./apps/AppProjectContent";

import { db, auth } from '../FirebaseConfig'
import RegularButton from "./buttons/RegularButton";

const getApp = async (id) => {
    let userId = auth.currentUser.uid;
    // get documents from `apps` collection where uid is the current user's uid
    // const querySnapshot = await getDocs(
    //     query(collection(db, "recommengine/users/"+ userId +"/data/apps"), where("packageName", "==", packageName))
    // );

    // const apps = [];

    // querySnapshot.forEach((doc) => {
        // console.log('an app found: ' + doc.data().name);
        // apps.push({ id: doc.id, ...doc.data()});
    // });

    // console.log(apps);
    // return apps[0];

    console.log('get app: userId: ', userId);
    console.log('get app by id: ', id);
    const docRef = doc(db, "recommengine/users/"+ userId +"/data/apps", id);
    
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        console.log("No such document!");
    }
}

function AppProject({ navigation }) {
    // const { app, id } = useParams();
    // console.log('useParams are: ', useParams());
    const { id } = useParams();
    const [ myApp, setMyApp ] = useState({})
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);

                if (loading) {
                    const app1 = getApp(id).then((app) => {
                        console.log(app)
                        setMyApp(app)
                    })

                    setLoading(false);
                }
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    return (
        <General title="App Details">
            <div className="flex flex-col">
                {/* <RegularButton text="Back to Dashboard" url="/dashboard" /> */}
                <RegularButton text="Back to Apps" url="/apps" />
            </div>
            <AppProjectContent myApp={myApp} id={id} />
        </General>
    )
}

export default AppProject;